<template>
    <div class="flow_path">
        <!-- <img src="../../assets/image/flow_path.png" class="flow_banner"> -->
        <div class="process">
            <h4>业务流程</h4>
            <!-- <img src="../../assets/image/liucheng.png"> -->
        </div>
    </div>
</template>

<script>
  export default {
    data() {
        return {}
    }
  }
</script>

<style lang="less" scoped>
.flow_path {
    text-align: center;
    margin-bottom: 130px;
    .flow_banner {
        width: 100%;
        height: 450px;
    }
    .process {
        margin-top: 32px;
        img {
            width: 1200px;
            margin-top: 32px;
        }
    }
}
</style>
